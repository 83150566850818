import React from "react";
import {Box, Heading, Form, FormField, TextInput, Button, CheckBox, FileInput} from "grommet";
import {QUERY_MAX_REGISTRATION_NUMBER, UPSERT_USER} from "./dipGQL";
import { GET_TREE } from "../aree/areeGQL";
import { useLazyQuery,useMutation } from '@apollo/client';
import {extractIdFormData, GOLF_ADD_ATTACHMENT, GOLF_GET_ATTACHMENT, QUERY_CONTENT_TYPE} from "../../../utilis/utils";
import TreeViewAree from "../../treeView/TreeView";
import { tenant, tenantTypeFirstUpperCase } from "../../tenant";
import moment from "moment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import {Typography} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FolderIcon from "@mui/icons-material/Folder";

export default function AddDip(props) {

  const [formData,setFormData]=React.useState({cost_ext:0,cost_int:0,date_of_birth:"1900-01-01",telephone_number:"",/*email:""*/});
  const [mutationUpsertDip]=useMutation(UPSERT_USER);
  const [selectedNodes,setSelectedNodes]=React.useState(formData?.users_userareas?.map((userArea)=>userArea.area_id )||[]);
  const [tree,setTree]=React.useState({});
  const [contentType,setContentType]=React.useState();
  const [elencoFile,setElencoFile]=React.useState([]);
  const [addAttachment]=useMutation(GOLF_ADD_ATTACHMENT);

    const [ queryGetTree
    ] = useLazyQuery(GET_TREE, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const tree=data[`${tenant}`]["whoami"].profile.treeArea   //[`areas${tenantTypeFirstUpperCase}First`][0].trees;
        if(tree){
          setTree(JSON.parse(tree).tree);
        }
      },
      notifyOnNetworkStatusChange: true, // did the work
    });

    const [ queryGetAttachments
    ] = useLazyQuery(GOLF_GET_ATTACHMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      console.log("DATA",data);
      const attachments=data[`${tenant}_attachments_attachment`]
      if(attachments){
        setElencoFile(attachments);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

     const [ queryMaxRegistrationNumber
      ] = useLazyQuery(QUERY_MAX_REGISTRATION_NUMBER, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const registration_number=data[`${tenant}`].userGolfRegistrationNumber;
        if(registration_number){
          setFormData({...formData,registration_number:registration_number});
        }
      },
      notifyOnNetworkStatusChange: true, // did the work
    });

     const [queryGetContentType] = useLazyQuery(QUERY_CONTENT_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let contentType=data[`django_content_type`][0]
      if(contentType){
        setContentType(contentType);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
  
  const onChangeTextInput=(event)=>{
    if(event.target.type==="number"){
      setFormData({...formData,[event.target.name]:parseFloat(event.target.value)})
    }else if(event.target.type==="date"){
      setFormData({...formData,[event.target.name]:moment(event.target.value).format("YYYY-MM-DD")})
    }else{
      setFormData({...formData,[event.target.name]:event.target.value})
    }
  }

  React.useEffect(()=>{
    if(props.dipendente){
      setSelectedNodes(props.dipendente.users_userareas && props.dipendente.users_userareas.map((userArea)=>userArea.area_id ))
      setFormData(props.dipendente)
    }else{ //Creazione nuovo utente
       queryMaxRegistrationNumber();
    }
    queryGetContentType({variables:{model:"usergolf"}})
  },[])

  React.useEffect(()=>{
    if(contentType && props.dipendente?.id){
         queryGetAttachments({variables:{contentTypeId:contentType?.id,objectId:props.dipendente.id}})
    }
  },[contentType])

  function downloadFile(allegatoSel){
    window.open("/media/"+allegatoSel.attachment)
  }
  
  function onUpsertDip(){
    let obj=extractIdFormData({...formData,aree:JSON.stringify({areeIds:selectedNodes})},true);
    delete obj.variables?.authUser;
    delete obj.variables?.auth_user;
    delete obj.variables["usersUserareas"]
    delete obj.variables["_Typename"]
    mutationUpsertDip(obj)
    .then((value)=>{
      //setFormData(value.data[`${tenant}`][`upsertUser${tenantTypeFirstUpperCase}`])
      alert("Utente aggiunto correttamente");
      if(contentType){
              for (let i = 0; i < elencoFile.length; i++) {
                addAttachment({
                  variables: {
                    name: elencoFile[i].name,
                    contentType: contentType.id,
                    objectId: props.dipendente.id,
                    attachment: JSON.stringify(elencoFile[i])
                  }
                })
                    .then((value) => console.log(value))
                    .catch((error) => console.log(error))
              }
            }
      onEscLayer();
    }).catch((value)=>{
      console.error(value);
      alert("Errore durante l'operazione");
    })
  }

  function onEscLayer(){
    if(props.onEscLayer){
        props.onEscLayer();
    }
    setFormData({});
  }
  
  return (
    <Box round pad="medium" direction="column" background="white" align="center" overflow="auto">
      <Heading level="2" margin="none" size="small" style={{fontWeight:'bold',textTransform: 'uppercase'}}>
        <Box direction="row" gap="large">
          {!props.dipendente?"Inserisci personale":"Aggiorna personale"}
          {/*<Button label="Scarica modello" href={"./modelliCsv/Modello_Personale.csv"} style={{width:'200px',fontSize:'20px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button>  */}
        </Box>
      </Heading>
      <Box fill gap="medium" pad={{ vertical: "small" }} align="center">
        <Form>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Nome">
              <TextInput
                name="name"
                value={formData && formData.name}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Cognome">
              <TextInput
                name="surname"
                value={formData && formData.surname}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Codice fiscale">
              <TextInput
                name="fiscal_code"
                value={formData && formData.fiscal_code}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          <Box pad="xsmall">
            <FormField required label="Data di nascita">
              <TextInput
                type="date"
                name="date_of_birth"
                value={formData && formData.date_of_birth}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Numero di telefono">
              <TextInput
                name="telephone_number"
                value={ formData && formData.telephone_number}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Matricola">
              <TextInput
                type="number"
                name="registration_number"
                value={formData && formData.registration_number}
                onChange={e => null}

              />
            </FormField>
          </Box>
        </Box>
        <Box direction="row" align="center">
          
          <Box pad="xsmall">
            <FormField required label="Costo Interno (€/Ora)">
              <TextInput
                placeholder="es. 10,5"
                type="number"
                name="cost_int"
                value={formData && formData.cost_int}
                onChange={e =>  onChangeTextInput(e)}
              />
            </FormField>
          </Box>
          <Box pad="xsmall">
            <FormField required label="Costo Esterno (€/Ora)">
              <TextInput
                placeholder="es. 15"
                name="cost_ext"
                type="number"
                value={formData && formData.cost_ext}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
          {
            /*
            <Box pad="xsmall">
              <FormField required label="Email">
                <TextInput
                  name="email"
                  value={formData && formData.email}
                  onChange={e =>  onChangeTextInput(e)}
                />
              </FormField>
            </Box>
            */
          }    
        </Box>
          <Box pad="xsmall">
            <FormField required label="Codice Gestionale Esterno">
              <TextInput
                name="external_erp_code"
                value={formData && formData.external_erp_code}
                onChange={e => onChangeTextInput(e)}
              />
            </FormField>
          </Box>
        <Box style={{overflowX:"hidden"}} overflow="auto">
          <TreeViewAree checkAssignedAree={true} selectedNodes={selectedNodes} setSelectedNodes={setSelectedNodes} addElement={false} loadTree={queryGetTree} tree={tree} singleChildForNode={false} multiSelect={true}/>
          {
            /**
             * <Box pad="xsmall">
                <FormField required label="Ruolo">
                  <Select
                    options={Roles}
                    value={newRuolo}
                    onChange={({ option }) => setNewRuolo(option)}
                  />
                </FormField>
              </Box>
             */
          }
        </Box>
        {
            <Box gap="small" >
              <CheckBox
                  checked={formData?.show_in_calendar || false}
                  label="Visualizza nel calendario"
                  onChange={(event) => setFormData({...formData,show_in_calendar:event.target.checked})}
              />
              <CheckBox
                checked={formData?.modify_registry || false}
                label="Modifica anagrafiche"
                onChange={(event) => setFormData({...formData,modify_registry:event.target.checked})}
              />
            </Box>
        }
        {
            props.dipendente &&
            <Box style={{marginTop:"10px"}}>
              <FileInput
                multiple
                name="file"
                onChange={event => {
                  const fileList = event.target.files;
                  const file=fileList[0];
                  var reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onloadend = function () {
                      const result=reader.result;
                     let fileObj={
                          name: file.name,
                          type:file.type,
                          content:result,
                          size:file.size,
                          modified: file.lastModifiedDate,
                      }
                      let elencoFileApp=[...elencoFile]
                      elencoFileApp.push(fileObj);
                    setElencoFile(elencoFileApp);
                  }
                }
                }
              />
              </Box>
          }
          {
                      elencoFile.length>0?

                        <List dense={false}>
                        {elencoFile.map((allegato)=>
                          <ListItem
                            secondaryAction={
                              <Box gap="small" direction="row">
                                <IconButton edge="end" aria-label="delete" onClick={()=>downloadFile(allegato)}>
                                  <DownloadIcon />
                                </IconButton>
                            </Box>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <FolderIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={<Typography variant="h6" style={ allegato.name.length>=50? { fontSize:"10px"}:{ fontSize:"15px"}}>{allegato.name}</Typography>}
                            />
                          </ListItem>
                        )}
                      </List>

                    :
                    null
                  }
      </Form>
      <Box direction="row" gap="medium" alignSelf="end">
          <Button label={!props.dipendente?"Aggiungi":"Aggiorna"} onClick={onUpsertDip} style={{width:'138px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
      </Box>
      </Box>
     
    </Box>
  )
}