import { gql } from '@apollo/client';
import {tenant,tenantType,tenantTypeFirstUpperCase} from "../tenant.js";

const ADD_TO_DO=gql`
mutation insertToDo($userCreator:bigint!,$checked:Boolean,$expiration:date,$text:String!,$title:String!,$haveAttachment:Boolean){
  insert_${tenant}_users_todo_one(object:{user_creator_id:$userCreator,checked:$checked,expiration:$expiration,text:$text,title:$title,have_attachment:$haveAttachment}){
    id
    title
    text
    expiration
  }
}
`

const UPDATE_TO_DO=gql`
mutation updateToDo($id:bigint!,$userCreator:bigint!,$checked:Boolean,$expiration:date,$text:String!,$title:String!,$haveAttachment:Boolean){
  update_${tenant}_users_todo_by_pk(pk_columns:{id:$id}, _set:{user_creator_id:$userCreator,checked:$checked,expiration:$expiration,text:$text,title:$title,have_attachment:$haveAttachment}){
    id
    title
    text
    expiration
  }
}
`

const DELETE_TO_DO=gql`
mutation deleteToDo($id:ID){
  ${tenant}{
    deleteToDo(id:$id){
      ok
      message
    }
  }
}
`

const QUERY_TO_DO=gql`
query getToDo($userId:bigint!){
  ${tenant}_users_userstodo(where:{user_id:{_eq:$userId}},distinct_on:to_do_id){
    users_todo{
      id
      title
      text
      expiration
      checked
      have_attachment
      users_user${tenantType}{
        id
        name
        surname
        registration_number
      }
    }
  }
}
`

const QUERY_ASSIGNED_TO_DO=gql`
query getToDoAssigned($userId: bigint!) {
  ${tenant}_users_userstodo(where: {user_id: {_eq: $userId}, users_todo: {checked: {_eq: false}}},distinct_on:to_do_id) {
    users_todo {
      id
      title
      text
      expiration
      checked
      have_attachment   
      users_user${tenantType} {
        id
        name
        surname
        registration_number
      }
    }
  }
}
`;

const QUERY_ARCHIVED_TO_DO=gql`
query getToDoAssigned($userId: bigint!) {
  ${tenant}_users_userstodo(where: {user_id: {_eq: $userId}, users_todo: {checked: {_eq: true}}},distinct_on:to_do_id) {
    users_todo {
      id
      title
      text
      expiration
      checked
      have_attachment
      users_user${tenantType} {
        id
        name
        surname
        registration_number
      }
    }
  }
}
`;

const GET_USER=gql`
query GetUsersToDo($areeId:[bigint!]){
  ${tenant}_users_userarea(where:{area_id:{_in:$areeId}},  distinct_on: user_id){
    users_user${tenantType}{
      id
      name
      surname
      registration_number
    }
  }
}
`

const CHECK_TO_DO=gql`
mutation checkToDo($id:bigint!,$checked:Boolean){
  update_${tenant}_users_todo_by_pk(pk_columns:{id:$id},_set:{checked:$checked}){
    id
  }
}
`

const ADD_USER_TO_DO_RELATION=gql`
mutation AddUsersToDoRelation($userId:bigint!,$toDoId:bigint!){
  insert_${tenant}_users_userstodo_one(object:{user_id:$userId,to_do_id:$toDoId}){
    id
  }
}
`

const DELETE_USER_TO_DO_RELATION=gql`
mutation DeleteUsersToDoRelation($toDoId:bigint!){
 delete_${tenant}_users_userstodo(where:{to_do_id:{_eq:$toDoId}}){
    returning{
      id
    }
  }
}
`

const GET_TO_DO_ASSIGNED_USER=gql`
query getToDoAssignedUser($toDoId:bigint!){
  ${tenant}_users_userstodo(where:{to_do_id:{_eq:$toDoId}},distinct_on:user_id){
    id
    users_user${tenantType}{
      id
      name
      surname
      registration_number
    }
  }
}
`

export {ADD_TO_DO,UPDATE_TO_DO,DELETE_TO_DO,QUERY_TO_DO,GET_USER,QUERY_ARCHIVED_TO_DO,QUERY_ASSIGNED_TO_DO,CHECK_TO_DO,ADD_USER_TO_DO_RELATION,DELETE_USER_TO_DO_RELATION,GET_TO_DO_ASSIGNED_USER}