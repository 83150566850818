import React, { useEffect, useState } from "react";
import {Box, Button,Text } from "grommet";
import {Home,UserWorker,List,MapLocation,Configure,Analytics,Notification} from "grommet-icons";
import { useHistory } from "react-router-dom";

/**
 * Componente che aggiunge la SideBar per dispositivi mobili nei componenti che non si muovono su App.js e nei quali vengono
 * passati dati tramite props.match
 */
const SideBarMobile=(props)=>{

    let history=useHistory();
    const [ruolo,setRuolo]=useState();
    const [hideRegisty,setHideRegistry]=React.useState(sessionStorage.getItem("hideRegistry")==="true" || null);


    /**
     * Per ridurre il lag della sidebar leggo il ruolo da session storage
     */
    function getRuolo(){
        if(sessionStorage.getItem('Role')!==undefined){
            setRuolo({Ruolo:sessionStorage.getItem('Role')});
        }
    }

    useEffect(()=>{
        getRuolo();
    },[])

    return(   
            <Box
                  height="97vh"
                  width="100%"
                  background="#FFFFFF"
                  overflow="auto"
                  style={{overflowX: 'hidden'}}
                  pad="small"
                  align="center"
                  >
                  <Box align="center" direction="column" gap="small" margin="medium" width="100%">
                      <Button style={{height:"80px",width:"95%",fontSize:'10px',background:props.page=="Home"?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() =>  history.push({
                        pathname: '/home',
                        page: "Home"
                    })}>
                          <Box pad="small" direction="row" align="center" gap="medium">
                              <Home color={props.page!="Home"?'black':'#FFFFFF'} />
                              <Text size="small" color={props.page!="Home"?'black':'#FFFFFF'}>Home</Text>
                          </Box>
                      </Button>
                      {ruolo !== undefined ?
                          ruolo == 'admin' ||  ruolo == 'Admin_Cantiere' ||  ruolo == 'Admin_Zona' ?
                      <Button style={{height:"80px",width:"95%",fontSize:'10px',background:props.page=="Attività"?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=> history.push({
                        pathname: '/home',
                        page: "Attività"
                    })}>
                          <Box pad="small" direction="row" align="center" gap="medium">
                              <List color={props.page!="Attività"?'black':'#FFFFFF'}/>
                              <Text size="small" color={props.page!="Attività"?'black':'#FFFFFF'}>Attività</Text>
                          </Box>
                      </Button>
                      :
                      null
                      :
                      null
                      }
                      {
                      ruolo !== undefined ?
                      ruolo == 'admin' ||  ruolo == 'Admin_Cantiere' ||  ruolo   == 'Admin_Zona' ?

                      <Button style={{height:"80px",width:"95%",fontSize:'10px',background:props.page=="Analitiche"?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=> history.push({
                        pathname: '/home',
                        page: "Analitiche"
                    })}>
                          <Box pad="small" direction="row" align="center" gap="medium">
                              <Analytics color={props.page!="Analitiche"?'black':'#FFFFFF'}/>
                              <Text size="small" color={props.page!="Analitiche"?'black':'#FFFFFF'}>Analitiche</Text>
                          </Box>
                      </Button>
                      :
                      null
                      :
                      null
                      }
                      <Button style={{height:"80px",width:"95%",fontSize:'10px',background:props.page=="Home"?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>  history.push({
                    pathname: '/home',
                    page: "Notifiche"
                })}

                        >
                          <Box pad="small" direction="row" align="center" gap="medium">
                              <Notification color={props.page!="Notifiche"?'black':'#FFFFFF'} />
                              <Text size="small" color={props.page!="Notifiche"?'black':'#FFFFFF'} >Notifiche</Text>
                          </Box>
                      </Button>
                      {
                      ruolo !== undefined ?
                      ruolo == 'admin' ||  ruolo == 'Admin_Cantiere' ||  ruolo == 'Admin_Zona' ||  ruolo == 'Meccanico' ?
                      <Button style={{height:"80px",width:"95%",fontSize:'10px',background:props.page=="Manutenzione"?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=> history.push({
                        pathname: '/home',
                        page: "Manutenzione"
                    })}>
                          <Box pad="small" direction="row" align="center" gap="medium">
                              <Configure color={props.page!="Manutenzione"?'black':'#FFFFFF'} />
                              <Text size="small"  color={props.page!="Manutenzione"?'black':'#FFFFFF'}>Macchinari</Text>
                          </Box>
                      </Button>
                      :
                      null
                      :
                      null
                      }
                      {ruolo !== undefined ?
                          (ruolo == 'admin' ||  ruolo == 'Admin_Cantiere' ||  ruolo == 'Admin_Zona' ||  ruolo == 'Meccanico') && hideRegisty ?
                      <Button style={{height:"80px",width:"95%",fontSize:'10px',background:props.page=="Anagrafiche"?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() =>  history.push({
                        pathname: '/home',
                        page: "Anagrafiche"
                    })}>
                          <Box pad="small" direction="row" align="center" gap="medium">
                              <UserWorker color={props.page!="Anagrafiche"?'black':'#FFFFFF'} />
                              <Text size="small" color={props.page!="Anagrafiche"?'black':'#FFFFFF'}>Anagrafiche</Text>
                          </Box>
                      </Button>
                      :
                      null
                      :
                      null
                      }
                      {ruolo !== undefined ?
                              ruolo == 'admin' ||  ruolo == 'Admin_Cantiere' ||  ruolo == 'Admin_Zona' ?
                      
                      <Button style={{height:"80px",width:"95%",fontSize:'10px',background:props.page=="Geo ref."?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=> history.push({
                        pathname: '/home',
                        page: "Geo ref."
                    })}>
                          <Box pad="small" direction="row" align="center" gap="medium">
                              <MapLocation color={props.page!="Geo ref."?'black':'#FFFFFF'} />
                              <Text size="small" color={props.page!="Geo ref."?'black':'#FFFFFF'}>Mappa</Text>
                          </Box>
                      </Button>
                      :
                      null
                      :
                      null
                      }
                  </Box>
              </Box>
    );
}

export default SideBarMobile;