import { gql } from '@apollo/client';
import { tenant } from '../tenant';

const LOGIN=gql`
mutation Login($username: String!,$password:String!) {
	${tenant}{
    tokenAuth(username:$username,password:$password){
      token
      payload
      refreshToken
      refreshExpiresIn
    }
  }
}`;

const REFRESH_TOKEN_MUTATION = gql`
mutation refreshToken($refreshToken: String!){
  refreshToken(refreshToken:$refreshToken){
    refreshToken
    refreshExpiresIn
    success
    errors
    token
    payload
  }
}`;

const QUERY_ME=gql`
query whoami {
  ${tenant} {
    whoami {
      id
      username
      lastName
      firstName
      email
      profile {
        id
        name
        surname
        treeRoot
        showInCalendar
        modifyRegistry
        toDoPermission
        supersetPermission
        warehousePermission
        modifyUser
        user {
          id
          isSuperuser
        }
        userareaSet{
          area{
            id
            name
            
          }
        }
        userroleSet {
          id
          role {
            id
            roleName
            roleauthorizationSet {
              id
              auth {
                id
                auth
              }
            }
          }
        }
      }
    }
  }
}
`

const QUERY_GET_CHILDREN_AREA=gql`
query GetChildrenArea($id:[ID!]){
  ${tenant}{
    areaGolfGetChildren(fatherId:$id){
      id
      name
    }
  }
}
`;

const GET_TENANT_DATA=gql`
query getTenantOption($name:String){
  core_tenant(where:{name:{_eq:$name}}){
    export_csv_external_code
  }
}
`


export {LOGIN,REFRESH_TOKEN_MUTATION,QUERY_ME,QUERY_GET_CHILDREN_AREA,GET_TENANT_DATA};