import React, { useState, useEffect } from "react";
import { Box, Grommet, Text, Tabs, Tab, ResponsiveContext } from 'grommet';
import AddAtt from "./AddAtt";
import SideBar from "../SideBar";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AttivitaDet from "./AttivitaDet";
import { useLocation } from "react-router-dom";
import { useLazyQuery } from '@apollo/client';
import { QUERY_ME } from "../apolloClient/loginGQL";
import { extractRoleFromWhoami } from "../../utilis/utils";
import {useParams} from "react-router-dom";

const theme = {
  global: {
    colors: {
      brand: '#397aa3',
      'accent-1': 'white',
      'focus': '#397aa3',
      "my-text-color": "",
      background: '#ecedf3',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
    },
  },
};

const App = () => {
  const location=useLocation();
  const [showSideBarMob,setShowSideBarMob]=React.useState(false);
  const [ruoli, setRuoli] = useState([]);
  const [index, setIndex] = React.useState(0);
  const [ queryMe
  ]  = useLazyQuery(QUERY_ME, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
      extractRoleFromWhoami(data,setRuoli);
    },
    onError:(error)=>{
      console.log(error);
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
  
  const onActive = (nextIndex) => setIndex(nextIndex);

  useEffect(() => {
    queryMe();
  }, []);

  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  
  
    /**
     * Funzione che permette di sapere le dimensioni aggiornate dello schermo
     */
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }
  useEffect(() => {
      if(location?.state?.modify===undefined){ //===undefined per distiungere se si arriva nella schermata dal calendario o da dashboard
         if(location?.state?.activityData?.id){//per click su calendario
           setIndex(0)
         }else{
            setIndex(1);
         }
      }else if(!location?.state?.modify){
        setIndex(0)
      }else if(location?.state?.modify){
        setIndex(1);
      }

  }, []);


  return (
    <Grommet theme={theme} themeMode="dark" className="font-link">
      <ResponsiveContext.Consumer>
        {size => (
        <Box fill>
          <Box  direction={width>=1100?"row":"column"}>   
            {
              width>=1100?
              <Box flex="false"> 
                <SideBar  pathLogo="./images/Logo1.png" page="Attività" ruolo={(ruoli[0]!==undefined)?ruoli[0].Ruolo:null}/>    
              </Box>
              :
              <AppBar position="static" style={{ background: '#397aa3'}}>
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Taskonnect
                </Typography>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => {
                   if(!showSideBarMob){
                    setShowSideBarMob(true);
                   }else{
                     setShowSideBarMob(false);
                   }      
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            }
         
            <Box overflow={{ horizontal: 'hidden' }} height="95vh" width="100vw">         
                    <Box  width="100%" align="center" pad="small">
                      <Tabs activeIndex={index} onActive={onActive} justify="start">
                        {
                          location && location.state && location.state.userId &&
                          <Tab title="Traccia">
                            <AttivitaDet location={location}/>
                          </Tab>
                        }
                     
                        <Tab title={location.state && location.state && location.state.activityData && location.state.activityData.id?"Modifica":"Aggiungi"}>
                          <Box overflow="auto"  width="95%" alignSelf="center" pad="small">
                            <AddAtt location={location}/>                        
                          </Box>
                        </Tab>
                        
                      </Tabs>
                    </Box>
            </Box>
          </Box>
        </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;