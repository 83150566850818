import { gql } from '@apollo/client';
import { tenant, tenantType, tenantTypeFirstUpperCase } from '../../tenant';

const UPSERT_USER = gql`
mutation UpsertUser($id:ID,$name:String!,$surname:String!,$fiscalCode:String,$dateOfBirth:Date,$costInt:Float,$costExt:Float,$aree:String!,$registrationNumber:Int!,$telephoneNumber:String,$modifyRegistry:Boolean,$showInCalendar:Boolean,$externalErpCode:String) {
  ${tenant}{
    upsertUser${tenantTypeFirstUpperCase}(input:{id:$id,name:$name,surname:$surname,fiscalCode:$fiscalCode,dateOfBirth:$dateOfBirth,costInt:$costInt,costExt:$costExt,aree:$aree,registrationNumber:$registrationNumber,telephoneNumber: $telephoneNumber,modifyRegistry:$modifyRegistry,showInCalendar:$showInCalendar,,externalErpCode:$externalErpCode}){
      user${tenantTypeFirstUpperCase}{
        id
        name
        surname
        fiscalCode
      }
    }
  }
}
`


const DELETE_USER = gql`
mutation deleteAreaType($id: ID!) {
  ${tenant}{
    deleteUser${tenantTypeFirstUpperCase}(id: $id) {
      message
      ok
    }
  }
}
`

const GET_USER = gql`
query GetUser($id:bigint!){
  ${tenant}_users_user${tenantType}_by_pk(id:$id){
    id
    fiscal_code
    name
    surname
    registration_number
    telephone_number
    cost_ext
    cost_int
    date_of_birth
    modify_registry
    show_in_calendar
    external_erp_code
    mailingList_mailinglistusers{
      id
      mailingList_mailinglist{
        id
        email
      }
    }
    users_userareas{
      id
      user_id
      area_id
    }
  }
}
`

const QUERY_MAX_REGISTRATION_NUMBER=gql`
query getMaxRegistrationNumber {
  ${tenant}{
    userGolfRegistrationNumber
  }
}
`;


export { UPSERT_USER, DELETE_USER,GET_USER,QUERY_MAX_REGISTRATION_NUMBER};