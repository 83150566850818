import { gql } from '@apollo/client';
import { tenant, tenantType, tenantTypeFirstUpperCase } from '../../tenant';


const UPSERT_MACHINE=gql`
mutation UpsertMachine($id:ID,$aree:String!,$chassisNumber:String!,$hourlyCost:Float!,$jobs:String!,$name:String!,$fuel:String,$year:Int,$externalErpCode:String,$notes:String,$carService:Date,$initialMachineTime:Float,$hourlyRentalCost:Float,$machineValue:Int,$accessories:Boolean,$machineType:ID!,$can:String!){
  ${tenant}{
      upsertMachine${tenantTypeFirstUpperCase}(input:{id:$id,aree:$aree,chassisNumber:$chassisNumber,hourlyCost:$hourlyCost,jobs:$jobs,name:$name,fuel:$fuel,year:$year,externalErpCode:$externalErpCode,notes:$notes,carService:$carService,initialMachineTime:$initialMachineTime,hourlyRentalCost:$hourlyRentalCost,machineValue:$machineValue,accessories:$accessories,machineType:$machineType,can:$can}){
      errors{
        field
      }
      machine${tenantTypeFirstUpperCase}{
        id
      }
    }
  }
}
`

const QUERY_MACHINE_TYPE=gql`
query MachineTypes{
    machineType_machinetype{
      id
      type_name
    }
  }
`

const QUERY_JOBS_MACHINE=gql`
query GetJobsMachine($areeId: [bigint!]!) {
  ${tenant}_jobs_jobsarea(where: {area_id: {_in: $areeId}}, order_by: {jobs_jobgolf: {name: asc}}) {
    id
    area_id
    job_id
    jobs_job${tenantType} {
      id
      name
    }
  }
}
`

const QUERY_JOBS_MACHINE_V2 = gql`
query job($areeId: [bigint!]!) {
  ${tenant}_jobs_job${tenantType}(where: {jobs_jobsareas: {area_id: {_in: $areeId}}}, order_by: {name: asc}) {
    id
    name
  }
}
`

const DELETE_MACHINE=gql`
mutation deleteMachine($id:ID!){
  ${tenant}{
    deleteMachine${tenantTypeFirstUpperCase}(id:$id){
      ok
      message
    }
  }
}
`

const QUERY_CANEDGE=gql`
query CanEdge {
  ${tenant}{
    canList{
      id
      canNumber
    }
  }
}
`


export {UPSERT_MACHINE,QUERY_MACHINE_TYPE,QUERY_JOBS_MACHINE,DELETE_MACHINE,QUERY_CANEDGE, QUERY_JOBS_MACHINE_V2};