import React from "react";
import {Box, Heading, Form, FormField, TextInput, Button, Layer} from "grommet";
import { useLazyQuery,useMutation } from '@apollo/client';
import { tenant, tenantTypeFirstUpperCase } from "../tenant";
import { extractIdFormData} from "../../utilis/utils.js";
import {UPSERT_PRODUCT} from "./ProductGQL.jsx";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";


export default function AddProduct(props) {

  const [formData,setFormData]=React.useState({area_id: JSON.parse(sessionStorage.getItem("Cantiere")) || JSON.parse(sessionStorage.getItem("areeId"))[0]});
  const [mutationAddProduct]=useMutation(UPSERT_PRODUCT);

  React.useEffect(()=>{
    if(props.product){
        let obj={...props.product}
        delete obj.__typename
        setFormData({...obj,area_id:JSON.parse(sessionStorage.getItem("Cantiere"))})
    }
  },[])


  const onChangeTextInput=(event)=>{
      setFormData({...formData, [event.target.name]: event.target.value})
  }

  function onUpsertProduct(){
      let formDataApp={...formData};
      if(formDataApp?.quantity || formDataApp?.quantity===0){
          delete formDataApp['quantity'];
          if(props?.product){
              delete formDataApp['area'];
              formDataApp['area_id'] = props?.product?.area?.id
          }

      }
    mutationAddProduct({variables: formDataApp})
    .then((value)=>{
      alert("Prodotto aggiunto correttamente");
      if(props?.getProducts){
          props.getProducts();
      }
      onEscLayer();
    }).catch((value)=>{
        console.error(value);
      alert("Errore durante l'operazione");
    })
  }

  function onEscLayer(){
    if(props.onEscLayer){
        props.onEscLayer();
    }
    setFormData({});
  }


  return (
      <Layer onEsc={onEscLayer} onClickOutside={onEscLayer}>
          <Box height="100%" round="medium" pad="15px" background="#ecedf3">
              <Box round pad="medium" direction="column" background="white" align="center" overflow="auto">
              <Heading level="2" margin="none" size="small">
                <Box direction="row" gap="large">
                  {!props.product?"Inserisci prodotto":"Aggiorna prodotto"}
                  {/*<Button label="Scarica modello" href={"./modelliCsv/Modello_Lavoro.csv"} style={{width:'200px',fontSize:'20px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase',textAlign:"center"}}></Button> */}
                </Box>
              </Heading>
              <Box fill gap="medium" pad={{ vertical: "small" }}>
                <Form>
                  <Box pad="xsmall">
                        <FormField required label="Nome">
                          <TextInput
                            name="name"
                            value={ formData?.name }
                            onChange={e => onChangeTextInput(e)}
                          />
                        </FormField>
                        <FormField required label="Codice">
                          <TextInput
                            name="code"
                            value={ formData?.code }
                            onChange={e => onChangeTextInput(e)}
                          />
                        </FormField>
                        <FormField required label="Descrizione">
                          <TextInput
                            name="description"
                            value={ formData?.description }
                            onChange={e => onChangeTextInput(e)}
                          />
                        </FormField>
                      {
                          /*
                           <FormField required label="Intervallo">
                          <TextInput
                            name="interval"
                            type="number"
                            step="0.01"
                            value={ formData?.interval }
                            onChange={e => onChangeTextInput(e)}
                          />
                        </FormField>
                          *
                          ***/
                      }

                      {
                          /*
                          * <FormField required label="Quantità">
                            <TextInput
                                name="quantity"
                                type="number"
                                step="0.01"
                                value={ formData?.quantity }
                                onChange={e => onChangeTextInput(e)}
                             />
                            </FormField>
                        * */
                      }
                  </Box>
                     <FormControlLabel control={
                            <Checkbox
                              checked={formData?.currentlyInStock}
                              onChange={(e)=>setFormData({...formData,currentlyInStock:e.target.checked})}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />} label="Attualmente in magazzino?"
                        />
                </Form>

                <Box direction="row" gap="medium" alignSelf="end" >
                  <Button margin="small" label= {!props.product?"Aggiungi":"Aggiorna"} onClick={onUpsertProduct} style={{width:'138px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
                </Box>
              </Box>
            </Box>
          </Box>
      </Layer>
  )
}