import { gql } from '@apollo/client';
import { tenant,tenantType } from '../tenant';

const QUERY_USERS=gql`
subscription get_users($areeId:[bigint!]) {
  ${tenant}_users_user${tenantType}(where: {users_userareas: {area_id: {_in: $areeId}}},order_by: {name: asc}) {
    id
    name
    surname
    fiscal_code
    date_of_birth
    cost_ext
    cost_int
    telephone_number
    registration_number
    modify_registry
    show_in_calendar
    external_erp_code
    users_userareas {
      area_id
    }
    auth_user{
      is_superuser
    }
   }
}
`

const QUERY_JOBS=gql`
subscription Jobs($areeId:[bigint!]){
  ${tenant}_jobs_job${tenantType}(where:{jobs_jobsareas:{area_id:{_in:$areeId}}}, order_by: {name: asc}){
    id
    name
    external_erp_code
    jobs_jobsareas{
      id
      area_id
      job_id
    }
  }
}
`

const QUERY_MACHINE=gql`
subscription GetMachine($areeId:[bigint!]){
  ${tenant}_machines_machine${tenantType}(where:{machines_machineareas:{area_id:{_in:$areeId}}}, order_by: {name: asc}){
    id
    name
    chassis_number
    fuel
    year
    external_erp_code
    hourly_cost
    notes
    car_service
    initial_machine_time
    hourly_rental_cost
    machineType_machinetype{
      id
      type_name
    }
    machine_value
   	machines_canmachines{
      id
      machines_can{
        id
        can_number
      }
    }
    accessories
    machineType_machinetype{
      id
      type_name
    }
    machines_machineareas{
      id
      area_area${tenantType}{
        id
        name
      }
    }
    machines_machinejobs{
      id
      jobs_job${tenantType}{
        id
        name
      }
    }
  }
}
`
const QUERY_MACHINE_TYPE=gql`
subscription MachineType{
  machineType_machinetype{
    id
    type_name
    machineType_typesschedules(order_by: {machineType_maintenanceschedule: {interval: asc}}){
      id
    	machineType_maintenanceschedule{
        id
        interval
        machineType_interventiontype{
          id
      		code
          note
        }
      }
    }
  }
}
`

const QUERY_SINGLE_MACHINE_TYPE=gql`
query MachineType($id:bigint!){
  machineType_machinetype_by_pk(id:$id){
    id
    type_name
    machineType_typesschedules(order_by: {machineType_maintenanceschedule: {interval: asc}}){
      id
    	machineType_maintenanceschedule{
        id
        interval
        machineType_interventiontype{
          id
      	  code
          note
        }
      }
    }
  }
}
`

const QUERY_CANEDGE=gql`
subscription CanEdge{
  ${tenant}_machines_can{
    id
    can_number
  }
}
`

export {QUERY_USERS,QUERY_JOBS,QUERY_MACHINE,QUERY_MACHINE_TYPE,QUERY_SINGLE_MACHINE_TYPE,QUERY_CANEDGE};