import React, { useState} from "react";
import { Box, Grommet, Text, Select, Button, ResponsiveContext, FileInput,Layer as LayerGrommet } from 'grommet';
import { FormClose } from "grommet-icons";
import AddDip from "./users/AddDip";
import AddLav from "./jobs/AddLav";
import AddMac from "./machine/AddMac";
import AddArea from "./aree/AddArea";
import { CircularProgress, Divider, Button as ButtonMui} from '@mui/material';
import { jsPDF } from 'jspdf'
import { QUERY_MACHINE} from "./registryComponentsGQL";
import { tenant,tenantType } from "../tenant";
import AddAreaType from "./areaType/AddAreaType";
import AddCan from "./canedge/AddCanedge";
import TreeAree from "./aree/TreeAree.js";
import TreeAreeType from "./areaType/TreeAreeType.js";
import TableUser from "./users/TableUser.js";
import TableMachine from "./machine/TableMachine.js";
import TableJobs from "./jobs/TableJobs.js";
import TableCan from "./canedge/TableCan.js";
import AddMaintenance from "./maintenance/AddMaintenance.js";
import {useSubscription} from "@apollo/client";
import {importJobs, importMachines, importUser} from "./importRegistryRequest.js";
import ArchivedAreaTable from "./archivedArea/archivedAreaTable.js";
var XLSX = require("xlsx");

const theme = {
  global: {
    colors: {
      brand: '#397aa3',
      'accent-1': 'white',
      'focus': '#ecedf3',
      "my-text-color": "",
      background: '#ecedf3',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
      color:"#397aa3",
    },
    select: {
      options:{
        extend:{
          fontWeight:'bold',
        },
      },
    },
  },
  layer: {
    border: {
      radius: 'large',
      intelligentRounding: true,
    },
  },
};

const Anagrafiche = (props) => {
  const [dropdown, setDropdown] = useState('PERSONALE');
  const [stringDropDown,setStringDropDown]=React.useState("");
  const [showLayer,setShowLayer]=React.useState(false);
  const [filePicked,setFilePicked]=React.useState();
  const [progress,setProgress]=React.useState(0);
  const [showLayerCant,setShowLayerCant]=React.useState(false);
  const [showLayerDip,setShowLayerDip]=React.useState(false);
  const [showLayerLavori,setShowLayerLavori]=React.useState(false);
  const [showLayerMac,setShowLayerMac]=React.useState(false);
  const [showLayerCan,setShowLayerCan]=React.useState(false);
  const [showLayerProd,setShowLayerProd]=React.useState(false);
  const [showLayerAcc,setShowLayerAcc]=React.useState(false);
  const [showLayerMachineType,setShowLayerMachineType]=React.useState(false);
  const [showLayerErr,setShowLayerErr]=React.useState(false);
  const [modifyAree,setModifyAree]=React.useState(false);
  const [areaSel,setAreaSel]=React.useState();
  const [modifyPersonale,setModifyPersonale]=React.useState(false);
  const [dipSel,setDipSel]=React.useState(false);
  const [modifyLavori,setModifyLavori]=React.useState(false);
  const [lavSel,setLavSel]=React.useState(false);
  const [modifyMacchinari,setModifyMacchinari]=React.useState(false);
  const [modifyCan,setModifyCan]=React.useState();
  const [macSel,setMacSel]=React.useState(false);
  const [canSel,setCanSel]=React.useState();
  const [qrCodeList,setQrCodeList]=React.useState([]);
  const [showLayerTipologiaAree,setShowLayerTipologiaAree]=React.useState(false);
  const [machineTypeSel,setMachineTypeSel]=React.useState();
  const [modifyMachineType,setModifyMachineType]=React.useState(false);
  const SIZE = "300x300";

  const { data, loading } = useSubscription(
      QUERY_MACHINE,{variables:{areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}}
  );

  React.useEffect(()=>{
    if(data && data[`${tenant}_machines_machine${tenantType}`]){
       createQR(data[`${tenant}_machines_machine${tenantType}`])
    }

  },[data])


  React.useEffect(()=>{
    if(dropdown==="MANUTENZIONI"){
      setShowLayerMachineType(true);
    }
  },[dropdown])


  var QRCode_Gen = require('qrcode.react');


  /**
	 * Funzione che genera e scarica il qrCode di un macchinario
	 */
   function downloadQR(nome) {
    const canvas = document.getElementById(nome);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qr-"+nome+".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  function createQR(macchine){
    let qrcodes=macchine.map((mac)=>{
      return(
       <QRCode_Gen
         style={{display: 'none'}}
         id={mac["chassis_number"].toString()}
         value={mac["name"]} //Change qr code logic on chassis_number
         size={290}
         level={"H"}
         includeMargin={true}
       />
       )
     })
     setQrCodeList(qrcodes);
  }

  const getImageSrc = (tagId) => {
    const content = tagId;
    const URL = `https://chart.googleapis.com/chart?chs=${SIZE}&cht=qr&chl=${content}&choe=UTF-8`;
    return URL;
  };

  function generateQrPDF(){
    // Defines the pdf
    const doc = new jsPDF();

    let x = 0;
    let y = 10;
    let j = 0;
    let k = 0;
    let items = 0;
    const qrSize = 70;
    const A4pageWidth = 210; // 210mm
    const A4pageHeight = 297; // 297mm
    const vPadding = 10;
    const textPadding = 10;

    for (let i = 0; i <  qrCodeList.length; ++i) {
      if (items >= 12) {
        doc.addPage();
        x = 0;
        y = 10;
        j = 0;
        k = 0;
        items = 0;
      }

      let imageData = new Image(300, 300);
      imageData.src = getImageSrc(qrCodeList[i].props.id)
      // doc.text("Scan me", x, y);
      doc.addImage(imageData, "PNG", x, y, qrSize, qrSize);
      if(qrCodeList[i].props.id.length>=25){
        doc.setFontSize(7);
      }else if(qrCodeList[i].props.id.length>=20 && qrCodeList[i].props.id.length<25 ){
        doc.setFontSize(10);
      }else if(qrCodeList[i].props.id.length>=15 && qrCodeList[i].props.id.length<=20){
        doc.setFontSize(13);
      }else{
        doc.setFontSize(15);
      }
      if(qrCodeList[i].props.id.length>=30){
        doc.text(qrCodeList[i].props.value.substring(30)+" ...",x + textPadding, y + qrSize - 3);
      }else{
        doc.text(qrCodeList[i].props.value,x + textPadding, y + qrSize - 3);
      }

      // console.log("x,y", x, y);
      items++;
      if (x >= A4pageWidth - qrSize) {
        x = 0;
        k = 0;
        y = ++j * qrSize + vPadding;
      } else {
        x = ++k * qrSize;
      }
    }
  doc.save('QR.pdf')
}



  /**
   * Funzione che gestisce la chiusura del layer per aggiunta anagrafiche in base alla tipologia di anagrafica selezionata
   */
  function onEscLayer(){
    if(dropdown=="AREE"){
      setShowLayerCant(false);
    }else if(dropdown=="RAGGRUPPAMENTO AREE"){
      setShowLayerTipologiaAree(false);
    }else if(dropdown=="PERSONALE"){
      setShowLayerDip(false);
    }else if(dropdown=="LAVORI"){
      setShowLayerLavori(false);
    }else if(dropdown=="MACCHINARI"){
      setShowLayerMac(false);
    }else if(dropdown=="PRODOTTI"){
      setShowLayerProd(false);
    }else if(dropdown=="ACCESSORI"){
      setShowLayerAcc(false);
    }else if(dropdown=="MANUTENZIONI"){
      setShowLayerMachineType(false);
    }else if(dropdown=="ERRORI"){
      setShowLayerErr(false);
    }else if(dropdown=="CANEDGE"){
      setShowLayerCan(false);
    }
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[dropdown];
      let importRow = XLSX.utils.sheet_to_json(worksheet);
      let dataJson={
        tenant:tenant,
        import:importRow
      }
      if(dropdown==="PERSONALE"){
        importUser(dataJson);
      }else if(dropdown==="MACCHINARI"){
        importMachines(dataJson);
      }else if(dropdown==="LAVORI"){
        importJobs(dataJson);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  /**
   * Layer per caricare file .json contente anagrafiche
   */
  function LayerCaricatore(){
    return(
   
          <LayerGrommet
          onEsc={() => {setShowLayer(false);setFilePicked()}}
          onClickOutside={() => {setShowLayer(false);setFilePicked()}}
        >
             <Box  align="center" pad="medium" gap="medium" direction="column">
              <FileInput
                multiple={{
                  max: 1,
                }}
                name="file"
                onChange={event => {
                  console.log(event.target);
                  handleFileUpload(event);
                }}
              />
              {filePicked===null?
              <Text style={{color:"red"}}>File non compatibile</Text>
              :
              null}
              <CircularProgress variant="determinate" value={progress} />
              <Button label="Chiudi" onClick={() =>{ setShowLayer(false);setFilePicked()}} /> 
           </Box>
         
        </LayerGrommet>
   
    )
  }

  /**
   * Funzione che gestisce l'apertura del layer per aggiunta anagrafiche in base alla tipologia di anagrafica selezionata
   */
  function mostraLayer(){
    if(dropdown=="AREE"){
      setShowLayerCant(true);
    }else if(dropdown=="RAGGRUPPAMENTO AREE"){
      setShowLayerTipologiaAree(true);
    }else if(dropdown=="PERSONALE"){
      setShowLayerDip(true);
    }else if(dropdown=="LAVORI"){
      setShowLayerLavori(true);
    }else if(dropdown=="MACCHINARI"){
      setShowLayerMac(true);
    }else if(dropdown=="PRODOTTI"){
      setShowLayerProd(true);
    }else if(dropdown=="ACCESSORI"){
      setShowLayerAcc(true);
    }else if(dropdown=="MANUTENZIONI"){
      setShowLayerMachineType(true);
    }else if(dropdown=="ERRORI"){
      setShowLayerErr(true);
    }else if(dropdown=="CANEDGE"){
      setShowLayerCan(true);
    }
  }

  /**
   * Funzione che ritorna il nome della tipologia di anagrafica selezionata al singolare
   */
  function getStringAnagrafica(dropdown){
    if(dropdown=="AREE"){
      setStringDropDown("NUOVA AREA");
    }else if(dropdown=="RAGGRUPPAMENTO AREE"){
      setStringDropDown("NUOVA RAGGRUPPAMENTO AREE");
    }else if(dropdown=="PERSONALE"){
      setStringDropDown("NUOVO PERSONALE");
    }else if(dropdown=="LAVORI"){
      setStringDropDown("NUOVO LAVORO");
    }else if(dropdown=="MACCHINARI"){
      setStringDropDown("NUOVO MACCHINARIO");
    }else if(dropdown=="PRODOTTI"){
      setStringDropDown("NUOVO PRODOTTO");
    }else if(dropdown=="ACCESSORI"){
      setStringDropDown("NUOVO ACCESSORIO");
    }else if(dropdown=="MANUTENZIONI"){
      setStringDropDown("NUOVA MANUTENZIONE");
    }else if(dropdown=="ERRORI"){
      setStringDropDown("ERRORE");
    }else if(dropdown=="CANEDGE"){
      setStringDropDown("NUOVO CANEDGE")
    }
  }

  return (
    <Grommet theme={theme} themeMode="dark" >
      <ResponsiveContext.Consumer>
        {size => (
        <Box height="97vh" width="100%" overflow="hidden" pad="small">
        {
          <Box direction="row" gap="medium" width="100%" > 
                <Box width="100%">
                    <Box fill>
                      <Box direction="column" align='start' >
                        <Box alignSelf='start' direction="row" align="center" margin="small" style={{textTransform:'uppercase'}} >
                            <Text color="#397aa3" margin="small" style={{fontWeight:'bold'}}>Seleziona l'anagrafica: </Text>
                            <Select
                           style={{fontWeight:'normal',textTransform:'uppercase'}}
                              margin="small"
                              options={props?.modifyRegistry? ['RAGGRUPPAMENTO AREE','AREE', 'AREE ARCHIVIATE' ,'PERSONALE', 'LAVORI',"MANUTENZIONI",'MACCHINARI', /*'PRODOTTI', 'ACCESSORI',*/]: ['AREE','AREE ARCHIVIATE', 'PERSONALE', 'LAVORI',"MANUTENZIONI",'MACCHINARI', /*'PRODOTTI', 'ACCESSORI',*/]} //IMPLEMENTAZIONE RUOLI DA RIFARE
                              value={dropdown}
                              onChange={({ option }) => {setDropdown(option);getStringAnagrafica(option)}}
                            />
                            { dropdown!=""?
                            <>
                            <Divider orientation="vertical"/>
                            <Box pad="small" direction="row" gap="small" >
                             {
                                props?.modifyRegistry &&
                               <Button style={{width:'150px',fontSize:'10px',background:"#ecedf3",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#397aa3',borderWidth:"3px"}} onClick={()=>setShowLayer(true)}>
                                <Box pad="small" direction="row" align="center" gap="medium">
                                    <Text size="small" color="#397aa3">CARICA DA FILE</Text>
                                </Box>
                              </Button>

                             }
                              {
                                (props?.modifyRegistry || (dropdown==="PERSONALE" && props?.modifyUser)) &&
                             <Button width={dropdown!=="MACCHINARI"?'250px':'300px'} style={{fontSize:'10px',background:"#ecedf3",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#397aa3',borderWidth:"3px"}} onClick={()=>mostraLayer()}>
                                <Box pad="small" direction="row" align="center" gap="medium">
                                    <Text size="small" color="#397aa3">INSERISCI {stringDropDown}</Text>
                                </Box>
                              </Button>
                              }
                              {
                                dropdown==="MACCHINARI" &&
                                <Button style={{width:'150px',fontSize:'10px',background:"#ecedf3",color:'white',fontWeight:'bold',textTransform: 'uppercase',borderStyle: 'solid',borderColor:'#397aa3',borderWidth:"3px"}} onClick={()=>generateQrPDF()}>
                                <Box pad="small" direction="row" align="center" gap="medium">
                                    <Text size="small" color="#397aa3">DOWNLOAD QRCODE</Text>
                                </Box>
                              </Button>
                              }
                            </Box>
                            </>
                            :
                            null
                            }
                            {showLayer?
                              <LayerCaricatore/>
                             :
                              null}
                        </Box>
                        {
                          dropdown==="RAGGRUPPAMENTO AREE"?
                            <>
                            
                              {showLayerTipologiaAree && (
                                <LayerGrommet  full={window.screen.width<1100?true:false} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                                <Box height="100%" round="medium" pad="15px" background="#ecedf3">
                                  <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>                         
                                  <AddAreaType onEscLayer={onEscLayer}/>
                                </Box>
                              </LayerGrommet>
                              )}
                            
                          
                            <TreeAreeType addElement={props?.modifyRegistry}/>
                          </>
                          :
                          null
                        }
                        {
                          dropdown==="AREE ARCHIVIATE"?
                            <>
                              <ArchivedAreaTable/>
                            </>
                          :
                          null
                        }
                        { dropdown == 'PERSONALE' ?
                        <>
                        {/* BUG #TK-31 FIX - Dimensione per inserimento anagrafiche */}
                        {
                          showLayerDip && (
                            <LayerGrommet  full={window.screen.width<1100} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                            <Box height="100%" round="medium" pad="15px" background="#ecedf3">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>                         
                              <AddDip onEscLayer={onEscLayer}/>
                            </Box>
                          </LayerGrommet>
                          )
                        }
                        	{ modifyPersonale ?
                              <>
                              <LayerGrommet onClickOutside={()=>setModifyPersonale(false)} onEsc={()=>setModifyPersonale(false)}>
                                <Box height="100%" round="medium" pad="15px" background="#ecedf3">
                                  <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setModifyPersonale(false)}/>}></Button>
                                  <AddDip dipendente={dipSel} onEscLayer={()=>setModifyPersonale(false)}/>
                                </Box>
                              </LayerGrommet>
                              </>
                              :
                             null
                            }
                          <TableUser modifyUser={props?.modifyUser} modifyRegistry={props?.modifyRegistry} setDipSel={setDipSel} setModifyPersonale={setModifyPersonale} aree={props.aree}/>
                          
                        </>
                        :
                        null
                        }

                        { dropdown == 'LAVORI' ?
                        <>
                        {/* BUG #TK-31 FIX - Dimensione per inserimento anagrafiche */}
                        {
                          showLayerLavori && (
                            <LayerGrommet  full={window.screen.width<1100?true:false} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                            <Box width="60vw" height="100%" round="medium" pad="15px" background="#ecedf3">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>
                              <AddLav onEscLayer={onEscLayer}/>
                            </Box>
                          </LayerGrommet>
                          )
                        }
                        { modifyLavori?
                            <>
                            <LayerGrommet onClickOutside={()=>setModifyLavori(false)} onEscLayer={()=>setModifyLavori(false)}>
                              <Box width="60vw" height="100%" round="medium" pad="15px" background="#ecedf3">
                                <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setModifyLavori(false)}/>}></Button>
                                <AddLav lavoro={lavSel} onEscLayer={()=>setModifyLavori(false)}/>
                              </Box>
                            </LayerGrommet>
                            </>
                            :
                            null
                          }
                          <TableJobs modifyRegistry={props?.modifyRegistry} setLavSel={setLavSel} setModifyLavori={setModifyLavori} aree={props.aree}/>
                        </>
                        :
                        null
                        }
                        {
                          dropdown==="CANEDGE"?
                            <>
                            
                              {showLayerCan && (
                                <LayerGrommet  full={window.screen.width<1100?true:false} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                                <Box height="100%" round="medium" pad="15px" background="#ecedf3">
                                  <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>                         
                                  <AddCan onEscLayer={onEscLayer}/>
                                </Box>
                              </LayerGrommet>
                              )}
                             { modifyCan ?
                              <>
                              <LayerGrommet onEscLayer={()=>setModifyCan(false)} onClickOutside={()=>setModifyCan(false)}>
                                <Box height="100%" pad="small" round="medium" background="#ecedf3">				
                                  <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setModifyCan(false)}/>}></Button>
                                  <AddCan can={canSel} onEscLayer={()=>setModifyCan(false)} />
                                </Box>
                              </LayerGrommet>
                              </>
                              :
                                null
                            }
                          
                            <TableCan modifyRegistry={props?.modifyRegistry} setCanSel={setCanSel} setModifyCan={setModifyCan}/>
                          </>
                          :
                          null
                        }
                        { dropdown == 'MACCHINARI' ?
                        <>
                        {/* BUG #TK-31 FIX - Dimensione per inserimento anagrafiche */}
                        {
                          showLayerMac && (
                            <LayerGrommet  full={window.screen.width<1100?true:false} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                            <Box height="100%" round="medium" pad="15px" background="#ecedf3">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>
                              <AddMac onEscLayer={onEscLayer}/>
                            </Box>
                          </LayerGrommet>
                          )
                        }
                        { modifyMacchinari ?
                          <>
                          <LayerGrommet onEscLayer={()=>setModifyMacchinari(false)} onClickOutside={()=>setModifyMacchinari(false)}>
                            <Box height="100%" pad="small" round="medium" background="#ecedf3">				
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setModifyMacchinari(false)}/>}></Button>
                              <AddMac macchina={macSel} onEscLayer={()=>setModifyMacchinari(false)} />
                            </Box>
                          </LayerGrommet>
                          </>
                          :
                            null
                        }
                          <TableMachine modifyRegistry={props?.modifyRegistry} modifyRegistry={props?.modifyRegistry} setMacSel={setMacSel} setModifyMacchinari={setModifyMacchinari} aree={props.aree} downloadQR={downloadQR}/>
                        </>
                        :
                        null
                        }

                        { dropdown == 'AREE' ?
                        <>
                        {/* BUG #TK-31 FIX - Dimensione per inserimento anagrafiche */}
                        {
                          showLayerCant && (
                            <LayerGrommet  full={window.screen.width<1100?true:false} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                            <Box height="100%" round="medium" pad="15px" background="#ecedf3">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>                          
                              <AddArea onEscLayer={onEscLayer}/>
                            </Box>
                          </LayerGrommet>
                          )
                        }
                        { modifyAree ?
                          <LayerGrommet onEscLayer={()=>setModifyAree(false)} onClickOutside={()=>setModifyAree(false)}>
                            <Box height="100%" round="medium" pad="15px" background="#ecedf3">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setModifyAree(false)}/>}></Button>
                              <AddArea area={areaSel} onEscLayer={()=>setModifyAree(false)}/>
                            </Box>
                          </LayerGrommet>
                            :
                          null
                        }
                          <TreeAree addElement={props?.modifyRegistry}/>
                        </>
                        :
                        null
                        }

                        { dropdown == 'MANUTENZIONI' ?
                        <>
                        {/* BUG #TK-31 FIX - Dimensione per inserimento anagrafiche */}
                        {
                          showLayerMachineType && (
                            <LayerGrommet  full={window.screen.width<1100} onEsc={onEscLayer} onClickOutside={onEscLayer}>
                            <Box height="100%" round="medium" pad="15px" background="#ecedf3">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={onEscLayer}/>}></Button>                         
                              <AddMaintenance onEscLayer={onEscLayer}/>
                            </Box>
                          </LayerGrommet>
                          )
                        }
                        {
                          modifyMachineType && (
                            <LayerGrommet  full={window.screen.width<110} onEsc={()=>setModifyMachineType(false)} onClickOutside={()=>setModifyMachineType(false)}>
                            <Box height="100%" round="medium" pad="15px" background="#ecedf3">
                              <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setModifyMachineType(false)}/>}></Button>                         
                              <AddMaintenance machineType={machineTypeSel} onEscLayer={()=>setModifyMachineType(false)}/>
                            </Box>
                          </LayerGrommet>
                          )
                        }
                         <></>
                        </>
                        :
                        null
                        }
                      </Box>
                    </Box>
                </Box>
            </Box>
            }
        </Box>
        )}
         
      </ResponsiveContext.Consumer>
      
    </Grommet>
  );
}

export default Anagrafiche;