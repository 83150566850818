import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Redirect} from "react-router-dom";
import App from './App.js';
import Anagrafiche from "./components/registryComponent/Anagrafiche.js";
import Attivita from "./components/activity/Attivita.js";
import AttivitaDet1 from "./components/activity/AttivitaDet1.js";
import AttivitaDet from "./components/activity/AttivitaDet.js";
import BucheDet from "./components/geoRef/BucheDet.js";
import Dashboard from "./components/dashboard/Dashboard.js";
import TracciaCampo from "./components/geoRef/TracciaCampo.js";
import Manutenzione from "./components/maintenance/Manutenzione.js";
import reportWebVitals from './reportWebVitals.js';
import LogIn from "./components/LogIn.js";
import Notification from "./components/notifications/Notifiche.js";
import AddAtt from "./components/activity/AddAtt.js"
import {WebSocketLink} from "apollo-link-ws";
import {getCookie,deleteCookie} from "./utilis/utils"
import { useHistory } from 'react-router-dom';

import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
} from '@apollo/client';

ReactDOM.render(

  <React.StrictMode>
  	<Root/>
  </React.StrictMode>,
  document.getElementById('root')
);

function Root(){

    const history = useHistory();
    const [header,setHeader]=React.useState({
          connectionParams: () => {
            return { headers: { Authorization: `Bearer ${getCookie('accessToken')}` } };
          }
        });
    const [wsLink]=React.useState(new WebSocketLink({
        uri: "ws://80.211.128.194:8080/v1/graphql",   //"wss://timemate.it/hasura",//"ws://80.211.128.194:8080/v1/graphql",  //
        reconnect:true,
        options: header
    }));

    const clearStorageAndCookies = () => {
        deleteCookie("accessToken")
        deleteCookie("refreshToken")
        localStorage.clear();
        sessionStorage.clear();
        if (window.location.pathname !== "/"){
             window.location.href = window.location.origin + "/";
        }
    }
    function setCookie(cookie){
        let cookieString = `${cookie.name}=${cookie.value}`;
        if (cookie.path) {
            cookieString = `${cookieString}; path=${cookie.path}`
        }
        if (cookie.maxAge) {
            cookieString = `${cookieString}; max-age=${cookie.maxAge}`
        }
        document.cookie = cookieString;
    }

    const getNewToken = async (refreshToken) => {
      const url =  window.location.hostname!=="localhost"? window.location + "api/login/refresh/":process.env.REACT_APP_BACKEND_HOST+"/api/login/refresh";
      const data = {
        refresh:refreshToken
      };

      fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(result => {
            let accessToken = result?.access;
            setCookie({name:'accessToken', value: accessToken, maxAge:process.env.REACT_APP_EXPIRATION_SECONDS});
            let refreshToken=result?.refresh;
            if(refreshToken){
                 setCookie({name:'refreshToken', value: refreshToken, maxAge:process.env.REACT_APP_EXPIRATION_SECONDS});
            }
            setHeader({
              connectionParams: () => {
                return { headers: { Authorization: `Bearer ${accessToken}` } };
              }
            });
            if(!accessToken){
                clearStorageAndCookies();
            }
        })
        .catch(error => {
          clearStorageAndCookies();
          console.error('Si è verificato un errore:', error);
        });

    };

   /* function handleWebSocketError(errorEvent) {
      console.error('WebSocket connection error:', errorEvent);
      const refreshToken=getCookie("refreshToken");
      getNewToken(refreshToken);
    }*/


    //wsLink.subscriptionClient.on('error', handleWebSocketError);

    const client = new ApolloClient({
      link: wsLink,
      cache: new InMemoryCache(),
      onError: ({ networkError, graphQLErrors }) => {
        if (graphQLErrors) {
            const refreshToken=getCookie("refreshToken");
              graphQLErrors.forEach((error) => {
                const errorMessage = error.message;
                if (errorMessage === 'Signature has expired' || errorMessage === 'You do not have permission to perform this action') {
                     // getNewToken(refreshToken);
                }
              });
        }
      },
    });

    if(!localStorage.getItem("accessToken")){
        <Redirect />
    }


    return(
            <Router>
                <Route exact path="/">
                    <LogIn/>
                </Route>
                <ApolloProvider client={client}>
                    <Route path="/home">
                        <App/>
                    </Route>
                    <Route path="/anagrafiche">
                        <Anagrafiche />
                    </Route>
                    <Route path="/attivita">
                        <AttivitaDet1 />
                    </Route>
                    <Route path="/attivitaDettaglio">
                        <Attivita />
                    </Route>
                    <Route path="/dashboard">
                        <Dashboard />
                    </Route>
                    <Route path="/dettaglio" render = { props => <AttivitaDet {...props} /> } >
                    </Route>
                    <Route path="/bucheDettaglio">
                        <BucheDet />
                    </Route>
                    <Route path="/manutenzioneMacchinari">
                        <Manutenzione />
                    </Route>
                    <Route path="/tracciaAdL">
                        <TracciaCampo />
                    </Route>
                    <Route path="/attivita/aggiungiAttivita">
                        <AddAtt />
                    </Route>
                    <Route path="/modificaTraccia/:id" render = { props => <TracciaCampo {...props} /> } >
                    </Route>
                    <Route path="/notifiche" render = { props => <Notification {...props} /> } >
                    </Route>
                </ApolloProvider>
            </Router>

    )
}

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
