import React from "react";
import {useLazyQuery, useMutation, useSubscription} from "@apollo/client";
import {Box, Grommet, Text} from "grommet";
import {DataGrid} from "@mui/x-data-grid";
import {tenant, tenantType} from "../tenant.js";
import {Button as ButtonMui, Divider, FormControl, FormLabel, Radio, RadioGroup} from "@mui/material";
import {
    DELETE_PRODUCT,
    GET_PRODUCTS,
    GET_PRODUCTS_BY_CODE, GET_PRODUCTS_BY_DESCRIPTION,
    GET_PRODUCTS_BY_NAME,
    UPSERT_PRODUCT
} from "./ProductGQL.jsx";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add.js";
import AddProduct from "./AddProduct";
import AddMovement from "./AddMovements.js";
import MovementList from "./MovementsList.jsx";
import LoadingLayer from "../loadingLayer/LoadingLayer.jsx";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
function WareHouse(props){

    const [products,setProducts]=React.useState([]);
    const [showAddProduct,setShowAddProduct]=React.useState(false);
    const [showAddMovement,setShowAddMovement] = React.useState(false);
    const [showAddMovementList,setShowMovementList] = React.useState(false)
    const [pageNumber,setPageNumber] = React.useState(1);
    const [deleteProduct]=useMutation(DELETE_PRODUCT)
    const [prodSel,setProdSel]=React.useState(null);
    const [textSearch,setTextSearch] = React.useState("");
    const [searchType, setSearchType] = React.useState('Codice');
    const [movementType,setMovementType] = React.useState("");

   const handleChangeSearchType = (event) => {
     setSearchType(event.target.value);
   };

    const [ queryGetProducts,{loading: loadingProducts}
    ] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
        const products=data[tenant]["products"]
        if(products){
            setProducts(products);
        }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

    const [ queryGetProductsByName,{loading: loadingProductsByName}
    ] = useLazyQuery(GET_PRODUCTS_BY_NAME, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
        const products=data[tenant]["productsByName"]
        if(products){
            setProducts(products);
        }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

    const [ queryGetProductsByCode,{loading: loadingProductsByCode}
    ] = useLazyQuery(GET_PRODUCTS_BY_CODE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
        console.log("DATA PRODUCT CODE",data);
        const products=data[tenant]["productsByCode"]
        if(products){
            setProducts(products);
        }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

     const [ queryGetProductsByDescription,{loading: loadingProductsByDescription}
    ] = useLazyQuery(GET_PRODUCTS_BY_DESCRIPTION, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
        const products=data[tenant]["productsByDescription"]
        if(products){
            setProducts(products);
        }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

    const columnsWareHouse = [
    { field: 'name', headerName: 'Nome Prodotto', width: 300 },
    { field: 'code', headerName: 'Codice', width: 200 },
        { field: 'description', headerName: 'Descrizione', width: 300 },
      //   { field: 'interval', headerName: 'Intervallo', width: 100 },
      { field: 'quantity', headerName: 'Quantità', width: 300,
       renderCell: (cellValues) => {
           let obj={...cellValues.row}
           delete obj.__typename
           return(
              <Box direction="row" gap="medium">
                  <Box width="150px">
                    <Text>{cellValues.row.quantity.toFixed(2)}</Text>
                  </Box>
                  {/*<ButtonMui
                      variant="contained"
                      color="success"
                      onClick={(event) => {
                          obj.quantity=obj.quantity+obj.interval
                          mutationProduct({variables:obj});
                          getProducts();
                      }}
                    >
                      +
                  </ButtonMui>
                   <ButtonMui
                      variant="contained"
                      color="error"
                      onClick={(event) => {
                          if(obj.quantity>0){
                               obj.quantity=obj.quantity-obj.interval
                               mutationProduct({variables:obj});
                               getProducts();
                          }
                      }}
                    >
                      -
                  </ButtonMui>*/}
              </Box>
          )
       }
      },
        { field: 'currentlyInStock', headerName: 'Censito', width: 200,
           renderCell: (cellValues) => {
            console.log(cellValues)
            return(
                cellValues.row.currentlyInStock ?
                    <Chip alignSelf={"center"} label="  " color="success" />
                    :
                    <Chip label="  " color="error" />
            )
           }
           },

        { field: 'Movimenti', headerName: 'Movimenti', width: 400,
                renderCell: (cellValues) => {
                    return(
                        <Box direction={"row"} gap={"small"}>
                              <ButtonMui
                                      variant="contained"
                                      color="success"
                                      onClick={(event) => {
                                          setProdSel(cellValues.row);
                                          setShowAddMovement(true);
                                          setMovementType("carico");
                                      }}
                                    >
                                      Carica
                                 </ButtonMui>
                                <ButtonMui
                                      variant="contained"
                                      color="error"
                                      onClick={(event) => {
                                          setMovementType("scarico");
                                          setProdSel(cellValues.row);
                                          setShowAddMovement(true);
                                      }}
                                    >
                                      Scarica
                                 </ButtonMui>
                              <ButtonMui
                                      variant="contained"
                                      color="primary"
                                      onClick={(event) => {
                                          setProdSel(cellValues.row);
                                          setShowMovementList(true);
                                      }}
                                    >
                                      Visualizza
                                 </ButtonMui>

                        </Box>

                    )
                }
            },
   {
    field: 'Modifica',
    headerName: 'Modifica',
    width: 120,
    renderCell: (cellValues) => {
      return (
        <ButtonMui
          variant="contained"
          color="primary"
          onClick={(event) => {
            setProdSel(cellValues.row);
            setShowAddProduct(true);
          }}
        >
          Modifica
        </ButtonMui>
      );},
   },
   {
    field: 'Cancella',
    headerName: 'Cancella',
    width: 150,
    renderCell: (cellValues) => {
      return (
        <ButtonMui
          variant="contained"
          color="error"
          onClick={(event) => {
              let res=window.confirm("Sei sicuro di voler eliminare il prodotto selezionato?");
              if(res){
                  deleteProduct({variables:{id:cellValues.row.id}})
                  getProducts();
              }
          }}
        >
          Cancella
        </ButtonMui>
      );},
     },
  ];

    const theme = {
  global: {
    colors: {
      brand: '#397aa3',
      'accent-1': 'white',
      'focus': '#ecedf3',
      "my-text-color": "",
      background: '#ecedf3',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
      color:"#397aa3",
    },
    select: {
      options:{
        extend:{
          fontWeight:'bold',
        },
      },
    },
  },
  layer: {
    border: {
      radius: 'large',
      intelligentRounding: true,
    },
  },
};

    function getProducts(){
        queryGetProducts({variables:{area: JSON.parse(sessionStorage.getItem("Cantiere")) || props.aree[0] || JSON.parse(sessionStorage.getItem("areeId"))[0],page:pageNumber,pageSize:1000}})
            .catch((err)=>console.log("ERROR",err))
    }
    function getProductsByName(text){
        queryGetProductsByName({variables:{area: JSON.parse(sessionStorage.getItem("Cantiere")) || props.aree[0] || JSON.parse(sessionStorage.getItem("areeId"))[0],name:text}})
            .catch((err)=>console.log("ERROR",err))
    }

    function getProductsByCode(text){
        queryGetProductsByCode({variables:{area: JSON.parse(sessionStorage.getItem("Cantiere")) || props.aree[0] || JSON.parse(sessionStorage.getItem("areeId"))[0],code:text}})
            .catch((err)=>console.log("ERROR",err))
    }

     function getProductsByDescription(text){
        queryGetProductsByDescription({variables:{area: JSON.parse(sessionStorage.getItem("Cantiere")) || props.aree[0] || JSON.parse(sessionStorage.getItem("areeId"))[0],desc:text}})
            .catch((err)=>console.log("ERROR",err))
    }


    function getProductsToProps(){
        if(textSearch!=="" && searchType==="Codice"){
            getProductsByCode(textSearch);
        }else if(textSearch!=="" && searchType==="Nome"){
            getProductsByName(textSearch);
        }else if(textSearch!=="" && searchType==="Descrizione"){
            getProductsByDescription(textSearch);
        }else{
            getProducts();
        }
    }

  React.useEffect(()=>{
     getProducts();
  },[props.aree])

    React.useEffect(()=>{
        if(textSearch===""){
            getProducts();
        }
    },[textSearch])

    return (
        <Grommet theme={theme} themeMode="dark" >
            <Box overflow="hidden" aligns="center" pad="small" margin="10px">
              <Text color="#397aa3" size="large" weight="bold" alignSelf="start">MAGAZZINO</Text>
              <Divider/>
            </Box>
            <Box pad={"small"} overflow="auto" height="83vh" width="100%" alignSelf='center' style={{marginTop:'10px'}}>
                   <Box marginBottom={"30px"} direction="raw" gap={"small"}>
                        <TextField
                            style = {{width: "60%"}}
                          id="outlined-controlled"
                          label={searchType==="Codice"?"Cerca per codice...":searchType==="Nome"?"Cerca per nome...":"Cerca per descrizione..."}
                          value={textSearch}
                          onChange={(event) => {
                            setTextSearch(event.target.value);
                          }}
                        />
                       <ButtonMui
                          variant="contained"
                          color="primary"
                          onClick={(event) => {
                              if(searchType==="Codice") {
                                  getProductsByCode(textSearch);
                              }else if(searchType==="Nome"){
                                  getProductsByName(textSearch);
                              }else {
                                  getProductsByDescription(textSearch);
                              }
                          }}
                        >
                          Cerca
                      </ButtonMui>
                        <ButtonMui
                          variant="contained"
                          color="warning"
                          onClick={(event) => {
                              setTextSearch("");
                             getProducts();
                          }}
                        >
                          Cancella ricerca
                      </ButtonMui>
                       <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">Tipo Ricerca</FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="Codice"
                            name="radio-buttons-group"
                            value={searchType}
                            onChange={handleChangeSearchType}
                          >
                              <Box direction={"raw"} gap={"small"}>
                                  <FormControlLabel value="Codice" control={<Radio />} label="Codice" />
                                  <FormControlLabel value="Nome" control={<Radio />} label="Nome" />
                                  <FormControlLabel value="Descrizione" control={<Radio />} label="Descrizione" />
                              </Box>
                          </RadioGroup>
                    </FormControl>
                   </Box>
                <div style={{ height: '70vh', width: '100%' }}>
                  <DataGrid
                    rows={products || []}
                    columns={columnsWareHouse}
                    pageSize={100}
                    onPageChange={(page, details)=>{
                        setPageNumber(page);
                    }}
                  />
                </div>
                  <Fab
                    onClick={()=> {setShowAddProduct(true)}}
                    style={{  margin: 0,
                        top: 'auto',
                        right: 60,
                        bottom: 10,
                        left: 'auto',
                        position: 'fixed',}} color="primary" aria-label="add">
                    <AddIcon />
                  </Fab>
            </Box>
            {
                showAddProduct && <AddProduct getProducts={getProductsToProps} product={prodSel} onEscLayer={()=>{setShowAddProduct(false);setProdSel(null);}}/>
            }
            {
                showAddMovement && <AddMovement movementType={movementType} getProducts={getProductsToProps} prodSel={prodSel} onEscLayer={()=>setShowAddMovement(false)}/>
            }
            {
                showAddMovementList && <MovementList getProducts={getProductsToProps} prodSel={prodSel} onEscLayer={()=>setShowMovementList(false)}/>
            }
            {
                (loadingProducts || loadingProductsByCode || loadingProductsByName || loadingProductsByDescription) && <LoadingLayer/>
            }
        </Grommet>
    )
}

export default WareHouse;

