import React, { useState, useEffect } from "react";
import { Box, Grommet, Notification as NotificationAp,TextArea,Text,CheckBox,Layer,Button,DateInput,TextInput,FileInput,Tabs,Tab } from 'grommet';
import  { Redirect } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import moment from "moment";
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { Accordion,AccordionSummary,AccordionDetails,Typography  } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    DELETE_TO_DO,
    GET_USER,
    QUERY_ARCHIVED_TO_DO,
    QUERY_ASSIGNED_TO_DO,
    QUERY_TO_DO,
    CHECK_TO_DO, ADD_USER_TO_DO_RELATION, UPDATE_TO_DO, ADD_TO_DO, DELETE_USER_TO_DO_RELATION, GET_TO_DO_ASSIGNED_USER
} from "./ToDoGQL.jsx";
import {tenant, tenantType} from "../tenant.js";
import {extractIdFormData, GOLF_ADD_ATTACHMENT, GOLF_GET_ATTACHMENT, QUERY_CONTENT_TYPE} from "../../utilis/utils.js";
import {AttachFile} from "@material-ui/icons";

const theme = {
  global: {
    colors: {
      brand: '#397aa3',
      'accent-1': 'white',
      'focus': '#397aa3',
      "my-text-color": "",
      background: '#ecedf3',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
    },
    menu:{
      icons:{
        color:'white',
        },
    },
  },
};

const ToDoList = (props) => {


  const [showLayer,setShowLayer]=React.useState(false);
  let [elencoToDo,setElencoToDo]=React.useState([]);
  const [showFab,setShowFab]=React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [elencoAllegati,setElencoAllegati]=React.useState([]);
  const [elencoFile,setElencoFile]=React.useState([]);
  const [mostraLayerCaricatore,setMostraLayerCaricatore]=React.useState(false);
  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [index, setIndex] = React.useState(0);
  const [checkNotifyNewNote,setCheckedNotifyNewNote]=React.useState(false);

  const [formData,setFormData]=React.useState({});
  const [usersList,setUsersList]=React.useState([]);
  const [selectedUser,setSelectedUser]=React.useState([]);
  const [addToDo]=useMutation(ADD_TO_DO);
  const [updateToDo]=useMutation(UPDATE_TO_DO);
  const [deleteToDo]=useMutation(DELETE_TO_DO);
  const [deleteUserToDoRelation]=useMutation(DELETE_USER_TO_DO_RELATION);
  const [addUserToDoRelation]=useMutation(ADD_USER_TO_DO_RELATION);
  const [addAttachment]=useMutation(GOLF_ADD_ATTACHMENT);
  const [checkToDo]=useMutation(CHECK_TO_DO);
  const [contentType,setContentType]=React.useState();
  const [ queryAllToDO
  ] = useLazyQuery(QUERY_TO_DO, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const toDoList=data[`${tenant}_users_userstodo`].map((el)=>el.users_todo);
      if(toDoList){
        setElencoToDo(toDoList);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryAssignedToDO
  ] = useLazyQuery(QUERY_ASSIGNED_TO_DO, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const toDoList=data[`${tenant}_users_userstodo`].map((el)=>el.users_todo);
      if(toDoList){
        setElencoToDo(toDoList);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryArchivedToDo
  ] = useLazyQuery(QUERY_ARCHIVED_TO_DO, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
       const toDoList=data[`${tenant}_users_userstodo`].map((el)=>el.users_todo);
      if(toDoList){
        setElencoToDo(toDoList);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [ queryUser
  ] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let users=data[`${tenant}_users_userarea`].map((el)=>el[`users_user${tenantType}`]).sort((a,b)=>a.name.localeCompare(b.name));
      if(users){
        setUsersList(users);
        setFormData({...formData,user_creator:users.find((el)=>el.id==sessionStorage.getItem("userId")).id})
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  const [queryGetAssignedUser] = useLazyQuery(GET_TO_DO_ASSIGNED_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let assignedUser=data[`${tenant}_users_userstodo`].map((el)=>el[`users_user${tenantType}`]);
      if(assignedUser){
        setSelectedUser(assignedUser);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

   const [queryGetContentType] = useLazyQuery(QUERY_CONTENT_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let contentType=data[`django_content_type`][0]
      if(contentType){
        setContentType(contentType);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

   const [ queryGetAttachments
    ] = useLazyQuery(GOLF_GET_ATTACHMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const attachments=data[`${tenant}_attachments_attachment`]
      if(attachments){
        setElencoFile(attachments);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });


  React.useEffect(()=>{
      queryAllToDO({variables:{userId:sessionStorage.getItem("userId")}});
      queryGetContentType({variables:{model:"product"}})
  },[])

    React.useEffect(()=>{
         queryUser({variables:{areeId:props.aree || JSON.parse(sessionStorage.getItem("areeId"))}});
    }, [props.aree])

    /**
     * Funzione che permette di sapere le dimensioni aggiornate dello schermo
     */
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
}, []);

  const handleChange = (panel) => (event, isExpanded) => {
      console.log(panel);
    setExpanded(isExpanded ? panel : false);
  };


  if (!sessionStorage.getItem("accessToken")) {
    return <Redirect to='/'  />
  }


  function eliminaAllegato(allegatoSel){
    /*var confirm=window.confirm("Sicuro di voler eliminare l'allegato?");
    if(confirm){
      let elencoAllegatiApp=[...elencoAllegati];
      elencoAllegatiApp=elencoAllegatiApp.filter((allegato)=>allegato.name != allegatoSel.name)
      firebase.firestore().collection('ToDo').doc(modificaToDo).update({
        Allegati: elencoAllegatiApp.map(allegato=>allegato.name)
      }).then((value)=>console.log("aggiornamento"));
      allegatoSel.delete().then(() => {
        setElencoAllegati(elencoAllegatiApp)
        alert("Allegato eliminato con successo");
      }).catch((error) => {

      });
    }*/
  }

  function downloadFile(allegatoSel){
        window.open("/media/"+allegatoSel.attachment)
  }

  function filterToDo(index){
    if(index===0){ //TUTTI
       queryAllToDO({variables:{userId:sessionStorage.getItem("userId")}});
    }else if(index===1){ //ASSEGNATI
      queryAssignedToDO({variables:{userId:sessionStorage.getItem("userId")}});
    }else if(index===2){ //ARCHIVIATI
      queryArchivedToDo({variables:{userId:sessionStorage.getItem("userId")}});
    }
  }

  function handleUpsertToDo(){
      let formDataApp={...formData};
      formDataApp["user_creator"]=usersList.find((el)=>el.id==sessionStorage.getItem("userId")).id
    let obj=extractIdFormData(formDataApp,true);
      obj.variables.haveAttachment=elencoFile.length>0;
    if(!formData?.id){
      addToDo(obj)
          .then((value)=>{
            const toDoId=value.data[`insert_${tenant}_users_todo_one`].id;
            deleteUserToDoRelation({variables:{toDoId:toDoId}})
            addUserToDoRelation({variables:{userId:sessionStorage.getItem("userId"),toDoId:toDoId}})
            for(let i=0;i<selectedUser.length;i++){
              addUserToDoRelation({variables:{userId:selectedUser[i].id,toDoId:toDoId}})
            }
            if(contentType){
                for(let i=0;i<elencoFile.length;i++){
                    addAttachment({variables:{name:elencoFile[i].name, contentType:contentType.id,objectId:toDoId,attachment:JSON.stringify(elencoFile[i])}})
                        .then((value)=>console.log(value))
                        .catch((error)=>console.log(error))
                }
            }
            filterToDo(index);
            alert("Nota aggiunta correttamente");
            setFormData({});
            setSelectedUser([]);
            setShowLayer(false);
          })
          .catch((error)=>{
            console.error(error);
            alert("Errore durante la creazione")
          });
    }else {
      obj.variables.userCreator=obj.variables["usersUser"+tenantType];
      delete obj.variables["usersUser"+tenantType];
      delete obj.variables._Typename;
      updateToDo(obj)
          .then((value) => {
            alert("Nota aggiornata correttamente");
            const toDoId=value.data[`update_${tenant}_users_todo_by_pk`].id
            deleteUserToDoRelation({variables:{toDoId:toDoId}})
            addUserToDoRelation({variables:{userId:sessionStorage.getItem("userId"),toDoId:toDoId}})
            for (let i = 0; i < selectedUser.length; i++) {
              addUserToDoRelation({variables: {userId: selectedUser[i].id, toDoId: toDoId}})
            }
            if(contentType && elencoFile.length>0){
                for(let i=0;i<elencoFile.length;i++){
                    addAttachment({variables:{name:elencoFile[i].name, contentType:contentType.id,objectId:toDoId,attachment:JSON.stringify(elencoFile[i])}})
                        .then((value)=>console.log(value))
                        .catch((error)=>console.log(error))
                }
            }
            filterToDo(index);
            setFormData({});
            setSelectedUser([]);
            setShowLayer(false);
          })
          .catch((error) => {
              console.error(error);
              alert("Errore durante l'aggiornamento")
          });
    }
  }

  const onActive = (nextIndex) => {filterToDo(nextIndex);setIndex(nextIndex)};

  function ToDoList(){
    return(
        <Box overflow="auto" gap="small" margin="small" >
              {
              elencoToDo.length>0?
                elencoToDo.map((toDo,i)=>
                <Accordion expanded={expanded === 'panel'+i} onChange={handleChange('panel'+i)} onClick={()=>queryGetAttachments({variables:{contentTypeId:contentType?.id,objectId:toDo.id}})}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Box direction="row" gap="small" width="100%">
                      <CheckBox
                          checked={toDo?.checked || false}
                          label=""
                          onChange={(event) => {
                            checkToDo({variables:{id:toDo.id,checked:event.target.checked}});
                            filterToDo(index)
                          }}
                        />
                        <Box direction="column" gap="small" width="100%">
                          <Box  direction="column" >
                            <Typography style={{fontSize:"20px"}} size="large"  weight="bold" style={toDo?.checked?{textDecoration:"line-through"}:null} color={"black"}>TITOLO: {toDo.title}</Typography>
                            <Typography size="medium" weight="bold" style={toDo?.checked?{textDecoration:"line-through"}:null} >Mittente: {`${toDo[`users_user${tenantType}`].name} ${toDo[`users_user${tenantType}`].surname} ${toDo[`users_user${tenantType}`].registration_number}`}</Typography>
                            <Typography size="medium" weight="bold" style={toDo?.checked?{textDecoration:"line-through"}:null} color={toDo.expiration<=moment(new Date).format('YYYY-MM-DD')?"red":"black"}>Scadenza: {toDo.expiration}</Typography>

                          </Box>
                            <Box direction="row" gap="small">
                                {toDo?.have_attachment?
                                  <AttachFile/>
                                  :
                                  null
                                }
                              <Typography  sx={toDo.checked?{ width: '100%', flexShrink: 0,fontWeight: 'bold',textDecoration:"line-through",wordWrap: "break-word" }:{ width: '100%', flexShrink: 0,fontWeight: 'bold',wordWrap: "break-word" }} variant={width<500?"h7":"h5"}>{toDo.title}</Typography>
                            </Box>
                        </Box>
                        <Box align="end" direction="row" >
                          <IconButton aria-label="delete" size="medium" onClick={()=>{
                              setFormData({...toDo});
                              queryGetAssignedUser({variables:{toDoId:toDo.id}});
                              queryGetAttachments({variables:{contentTypeId:contentType?.id,objectId:toDo.id}})
                              setShowLayer(true)  }}>
                            <EditIcon size="medium" color="primary" />
                          </IconButton>
                          <IconButton aria-label="delete" size="medium" onClick={()=>{
                            let result=window.confirm("Sicuro di voler eliminare la nota?");
                            if(result){
                                deleteToDo({variables:{id:toDo.id}})
                                    .then((value)=>filterToDo(index));
                              }
                            }
                          }>
                            <DeleteIcon size="medium" color="primary" />
                          </IconButton>
                        </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box direction="column" gap="small" flex={false}>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        id="standard-textarea"
                        placeholder="Placeholder"
                        multiline
                        variant="standard"
                        value={toDo.text}
                      />
                      {
                      elencoFile.length>0?

                        <List dense={false}>
                        {elencoFile.map((allegato)=>
                          <ListItem
                            secondaryAction={
                              <Box gap="small" direction="row">
                                <IconButton edge="end" aria-label="delete" onClick={()=>downloadFile(allegato)}>
                                  <DownloadIcon />
                                </IconButton>
                            </Box>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <FolderIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={<Typography variant="h6" style={ allegato.name.length>=50? { fontSize:"10px"}:{ fontSize:"15px"}}>{allegato.name}</Typography>}
                            />
                          </ListItem>
                        )}
                      </List>

                    :
                    null
                  }
                  </Box>
                  </AccordionDetails>

                </Accordion>
                  )
                  :
                  <Box align="center" alignSelf="center">
                    <Text color="#397aa3" size="large" weight="bold" alignSelf="start">Non ci sono note al momento</Text>
                  </Box>
                }
                  </Box>
    )
  }

  return (
    <Grommet theme={theme} themeMode="dark" className="font-link">
      <Box align="center" margin="small" height="94vh" overflow="hidden">
      <Box width="100%" overflow="hidden" aligns="center" pad="small" margin="10px">
          <Text color="#397aa3" size="large" weight="bold" alignSelf="start">TO DO LIST</Text>
          <Divider/>
      </Box>
        <Box width="100%" overflow="auto" gap="small"  height="100vh">
          <Tabs activeIndex={index} onActive={onActive}>
            <Tab title="TUTTI">
              <ToDoList/>
            </Tab>
              <Tab title="ASSEGNATI">
                 <ToDoList/>
              </Tab>
              <Tab title="ARCHIVIATI">
                 <ToDoList/>
              </Tab>
            </Tabs>
          </Box>
        {showLayer && (
         <Layer
         onEsc={() => {setShowLayer(false);setFormData({});setSelectedUser([])}}
         onClickOutside={() => {setShowLayer(false);setFormData({});setSelectedUser([])}}
       >
        <Box round width="600px" pad="medium" direction="column" background="white" overflow="auto" gap="medium" margin="10px">
                <Box flex={false}>
                    <Text style={{marginRight:'10px',marginTop:'-3px'}}>Seleziona la data di fine: *</Text>
                            <DateInput
                              format="dd/mm/yyyy"
                              value={formData?.expiration || moment(new Date()).format()}
                              onChange={({ value }) => {setFormData({...formData,expiration:moment(value).format()})}}
                              calendarProps={{ size: "small", locale: "it-IT", daysOfWeek: true, firstDayOfWeek: 1 }}
                            />
                </Box>
                <Text style={{marginRight:'10px',marginTop:'-3px'}}>Visibile da:</Text>
                <Autocomplete
                    disableCloseOnSelect
                    multiple
                    disablePortal
                    id="combo-box-demo"
                    options={usersList}
                    value={selectedUser}
                    onChange={(event,newValue,index) => {
                      setSelectedUser(newValue);

                    }}
                    getOptionLabel={(option)=>option.name + " "+option.surname+" "+option.registration_number}
                    renderInput={(params) => <TextField {...params} size="medium" variant="standard"/>}
                  />




                <TextInput
                placeholder="Inserisci titolo nota"
                value={formData?.title || ""}
                onChange={event =>{ setFormData({...formData,title:event.target.value})}}
                />


                <Box flex={false}>
                  <TextArea
                    value={formData?.text || ""}
                    style={{height:180}}
                    resize={false}
                    placeholder="Inserisci nota"
                    onChange={e =>{setFormData({...formData,text:e.target.value})}}
                  />
                </Box>

            {
              elencoAllegati.length>0?

              <List dense={false}>
              {elencoAllegati.map((allegato)=>
                <ListItem
                  secondaryAction={
                    <Box gap="small" direction="row">
                      <IconButton edge="end" aria-label="delete" onClick={()=>downloadFile(allegato)}>
                        <DownloadIcon />
                      </IconButton>
                   </Box>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                     primary={<Typography variant="h6" style={ allegato.name.length>=50? { fontSize:"10px"}:{ fontSize:"15px"}}>{allegato.name}</Typography>}
                  />
                </ListItem>
              )}
            </List>

              :
              null
            }
                  <FileInput
                                    multiple
                                    name="file"
                                    onChange={event => {
                                      const fileList = event.target.files;
                                      const file=fileList[0];
                                      var reader = new FileReader();
                                      reader.readAsDataURL(file);
                                      reader.onloadend = function () {
                                          const result=reader.result;
                                         let fileObj={
                                              name: file.name,
                                              type:file.type,
                                              content:result,
                                              size:file.size,
                                              modified: file.lastModifiedDate,
                                          }
                                          let elencoFileApp=[...elencoFile]
                                          elencoFileApp.push(fileObj);
                                        setElencoFile(elencoFileApp);
                                      }
                                    }
                                    }
                                  />
                   <FormControlLabel
                                    label="Ricevi notifica alla scadenza"
                                    control={
                                    <Checkbox
                                        checked={checkNotifyNewNote}
                                         onChange={(e)=>{setCheckedNotifyNewNote(e.target.checked)}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        label=""
                                      />
                                    }
                                    />
          <Box direction="row" alignSelf="center">
            <Button label={formData?.id?"Aggiorna":"Crea"} onClick={handleUpsertToDo} style={{width:'138px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>
            <Button type="reset" label="Chiudi" onClick={()=>{setFormData({});setShowLayer(false);setSelectedUser([])}} style={{marginLeft:'20px',height:'34px',width:'138px',fontSize:'15px',background:'#c51d34',color:'white',fontWeight:'bold',textTransform: 'uppercase', borderColor:'#c51d34'}} />
          </Box>
        </Box>
        {
          mostraLayerCaricatore?
          <Layer onEsc={()=>setMostraLayerCaricatore(false)} onClickOutside={()=>setMostraLayerCaricatore(false)}>
            <Box align="center" gap="medium" pad="small">
              <CircularProgress style={{'color': 'green'}} variant="determinate" value={90} />
              <Text>Caricamento...</Text>
            </Box>
          </Layer>
          :
          null
        }

       </Layer>
        )
        }
        {
          !showFab?
          <Fab
            onClick={()=>  {setElencoFile([]);setElencoAllegati([]);setShowLayer(true)}}
            style={{  margin: 0,
            top: 'auto',
            right: 30,
            bottom: 30,
            left: 'auto',
            position: 'fixed',}} color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        :
          <Fab
            onClick={()=> {setElencoFile([]);setElencoAllegati([]);setShowLayer(true)}}
            style={{  margin: 0,
            top: 'auto',
            right: 17,
            bottom: 80,
            left: 'auto',
            position: 'absolute',}} color="successs" aria-label="add">
            <AddIcon />
          </Fab>
        }
      </Box>
    </Grommet>
  );
}

export default ToDoList;